import * as React from "react";
import Layout from "../components/layout";

import "./index.css";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <main>
        <title>Kaleb Davenport Home Page</title>
        <h4 class="funky-font">Hi! I'm Kaleb.</h4>
        <h4>I love building apps because it challenges me to</h4>
      </main>
    </Layout>
  );
};

export default IndexPage;
